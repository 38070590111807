import axios from 'axios';

const fd_Axios = axios.create({
  baseURL: process.env.NODE_ENV === 'development'
  ? 'https://intranet.exim-alpes-maritimes.fr/api/v1/'
  : 'https://intranet.exim-alpes-maritimes.fr/api/v1/', //YOUR_API_URL HERE
  headers: {
    'Accept': 'application/json',
    'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
  },
});


fd_Axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);


export const setAuthTokenFormatData = (token) => {
  if (token) {
    fd_Axios.defaults.headers.common['authorization'] = token;
    localStorage.setItem('token', token);
  } else {
    delete fd_Axios.defaults.headers.common['authorization'];
    localStorage.removeItem('token');
  }
};

export default fd_Axios;
