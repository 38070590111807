import React from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {FaChevronDown} from 'react-icons/fa';
import {RiShutDownLine} from 'react-icons/ri';
import {BsCheck2Square} from 'react-icons/bs';
import {GrMailOption} from 'react-icons/gr';
import {FiUser} from 'react-icons/fi';
import styles from './index.module.scss';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {useAuthMethod, useAuthUser} from '../../../../utility/AuthHooks';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';
import {Dropdown, Image} from 'react-bootstrap';

const UserInfo = ({hasColor, userInfoClass, userInfoContentClass,setHeaderMessageShow,headerMessageShow}) => {
  const {themeMode} = useThemeContext();
  const {logout} = useAuthMethod();
  const {user} = useAuthUser();
  const history = useHistory();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();

  const getUserAvatar = () => {
    // if (user.prenom) {
    //   return user.prenom.charAt(0).toUpperCase();
    // }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const getImage = () =>  {
      var baseURL= process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5100/uploads/profile/' : 'https://intranet.exim-alpes-maritimes.fr/uploads/profile/';
      if(user.photoURL){
        var image = user.photoURL != null ? baseURL+user.photoURL:  '/assets/images/placeholder.jpg';
      }else{
        var image = '/assets/images/placeholder.jpg';
      }

      return image;
  }

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx(
            styles.crUserInfo,
            styles.crUserInfoHasColor,
            'd-flex flex-column justify-content-center bg-transparent',
            {
              light: themeMode === 'light',
            },
            userInfoClass
          )}>
          <Dropdown className='w-100' align='end'>
            <Dropdown.Toggle
              id='dropdown-basic'
              className={clsx(
                styles.dropdownToggleBtn,
                'p-0 bg-transparent border-0 d-flex align-items-center w-100 shadow-none',
              )}>
              {user.image != null ? (
                <Image
                  className={styles.crUserInfoAvatar}
                  src={getImage()}
                  roundedCircle
                />
              ) : (
                <span className={styles.crUserInfoAvatar}>
                  {getUserAvatar()}
                </span>
              )}
              <span className={clsx(styles.crUserInfoContent, userInfoContentClass)}>
                <span className='d-flex align-items-center justify-content-between'>
                  <h3
                    className={clsx(styles.crUserName, '', {
                      light: themeMode === 'light',
                    })}>
                    {user.email ? user.email : 'Admin'}
                  </h3>
                  <span
                    className={clsx(
                      styles.crUserArrow,
                      'd-flex justify-content-center align-items-center',
                    )}>
                    <FaChevronDown />
                  </span>
                </span>
                {/* <a href="#"
                  className={clsx(styles.crUserDesignation, 'text-primary')}>
                  Déconnexion
                </a> */}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item className="item" onClick={() => history.push('/extra-pages/user-profile')}><FiUser /> Voir le profil</Dropdown.Item>
              
              {user.roles != 'admin' && <Dropdown.Item onClick={() => setHeaderMessageShow(true)}><GrMailOption /> Mes notifications</Dropdown.Item> } 
              <Dropdown.Item onClick={() => history.push('/factures')}><BsCheck2Square /> Mes factures</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="logout-dropdown" onClick={() => logout()}><RiShutDownLine /> Déconnexion</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx(
            styles.crUserInfo,
            'd-flex flex-column justify-content-center bg-transparent',
            {
              light: themeMode === 'light',
            },
            userInfoClass
          )}>
          <Dropdown className='w-100' align='end' style={{backgroundImage: "none"}}>
            <Dropdown.Toggle
              id='dropdown-basic'
              className={clsx(
                styles.dropdownToggleBtn,
                'p-0 bg-transparent border-0 d-flex align-items-center w-100 shadow-none',
              )}>
              {user.photoURL ? (
                <Image
                  className={styles.crUserInfoAvatar}
                  src={user.photoURL}
                  roundedCircle
                />
              ) : (
                <span className={styles.crUserInfoAvatar}>
                  {getUserAvatar()}
                </span>
              )}
              <span className={clsx(styles.crUserInfoContent, 'ms-3', userInfoContentClass)}>
                <span className='d-flex align-items-center justify-content-between'>
                  <h3
                    className={clsx(styles.crUserName, 'text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user.prenom ? 'Bonjour ' + user.prenom : 'Admin'}
                  </h3>
                  <span
                    className={clsx(
                      styles.crUserArrow,
                      'd-flex justify-content-center align-items-center',
                    )}>
                    <FaChevronDown />
                  </span>
                </span>
                <span
                  className={clsx(styles.crUserDesignation, 'text-truncate')}>
                  System Manager
                </span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => history.push('/extra-pages/user-profile')}>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => logout()}> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
  userInfoClass: PropTypes.string,
  userInfoContentClass: PropTypes.string,
};
