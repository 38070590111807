import moment from 'moment';

export function formatDate(date) {
   return (moment(date).utc().format('DD/MM/YYYY'));
}

export function removeObjectWithId(arr, id) {
   // Making a copy with the Array from() method
   const arrCopy = Array.from(arr);
 
   const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id);
   arrCopy.splice(objWithIdIndex, 1);
   return arrCopy;
 }

export function getPathFile(filename) {
   var baseURL= process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5100/uploads/factures/' : 'https://intranet.exim-alpes-maritimes.fr/uploads/factures/';
   return baseURL+filename;
}

export function getPathFileDossier(filename, numeroDossier) {
   var baseURL= process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5100/uploads/dossiers/' : 'https://intranet.exim-alpes-maritimes.fr/uploads/dossiers/';
   return baseURL+numeroDossier+'/'+filename;
}

export function getPathFileImport(filename) {
   var baseURL= process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5100/uploads/imports/' : 'https://intranet.exim-alpes-maritimes.fr/uploads/imports/';
   return baseURL+filename;
}

export function getLogoAgence(logo) {
   var baseURL= process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5100/uploads/profile/' : 'https://intranet.exim-alpes-maritimes.fr/uploads/profile/';
   return baseURL+logo;
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
 export function humanFileSize(bytes, si=false, dp=1) {
   const thresh = si ? 1000 : 1024;
 
   if (Math.abs(bytes) < thresh) {
     return bytes + ' B';
   }
 
   const units = si 
     ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
     : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
   let u = -1;
   const r = 10**dp;
 
   do {
     bytes /= thresh;
     ++u;
   } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
 
 
   return bytes.toFixed(dp) + ' ' + units[u];
 }
 
 export function handleFileName(filename = ''){
   var regExp = /\(([^)]+)\)/;
   console.log(filename)
   var matches = regExp.exec(filename);

   //matches[1] contains the value between the parentheses
   // console.log(matches[1]);
   return matches ? matches[1] : filename;
 }
