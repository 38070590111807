import axios from 'axios';

const jwtAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development'
  ? 'https://intranet.exim-alpes-maritimes.fr/api/v1/'
  : 'https://intranet.exim-alpes-maritimes.fr/api/v1/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});


jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['authorization'] = token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
